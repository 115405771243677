export default {
  props: {
    artwork: {
      type: Object,
      required: true,
    },
    modifier: {
      type: String,
      default: 'artwork',
    },
  },

  components: {},

  mounted() {},

  data() {
    return {
      fields: {
        identification: {
          title: this.artwork.title,
          objectNames: this.artwork.objectNames.length ? this.artwork.objectNames.join(', ') : null,
          location: this.artwork.location,
        },
        creation: {
          creators: 'computed',
          period: this.artwork.period,
          date: 'computed',
        },
        technical: {
          materials: 'computed',
          techs: 'computed',
          dimensions: 'computed',
        },
      },
    };
  },

  computed: {
    materials: {
      get() {
        const mat = [];
        this.artwork.materials.forEach((m) => {
          mat.push(m.name);
        });
        return mat.length ? mat.join(', ') : null;
      },
    },
    techniques: {
      get() {
        const tech = [];
        if (this.artwork.techniques) {
          this.artwork.techniques.forEach((t) => {
            tech.push(t.name);
          });
        }
        return tech.length ? tech.join(', ') : null;
      },
    },
    creators: {
      get() {
        const names = [];
        this.artwork.creators.forEach((c) => {
          names.push(c.name);
        });
        return names.length ? names.join(', ') : null;
      },
    },
    dateHtml: {
      get() {
        let html = '';
        this.artwork.dates.forEach((date) => {
          let dateHtml = '';
          if (date.startDate) {
            dateHtml += `<span>${this.$t('specs.date.from')} &nbsp</span>${date.startDatePrecision ? date.startDatePrecision : ''} ${date.startDate ? date.startDate : ''}`;
          }
          if (date.startDate && date.endDate) {
            dateHtml += `<br />`;
          }
          if (date.endDate) {
            dateHtml += `<span>${this.$t('specs.date.to')} &nbsp</span>${date.endDatePrecision ? date.endDatePrecision : ''} ${date.endDate ? date.endDate : ''}`;
          }
          dateHtml = `<div>${dateHtml}</div>`;
          html += dateHtml;
        });
        return html.length ? html : null;
      },
    },
    dimensionsHtml: {
      get() {
        let html = '';
        this.artwork.dimensions.forEach((d) => {
          html += `<span>${d.part ? d.part : 'n'} &nbsp</span>${d.type ? d.type : ''}:${d.precision ? d.precision : ''}${d.precision ? '&nbsp;' : ''}${d.value ? d.value : 'n'}${d.unit ? d.unit : ''}`;
        });
        return html.length ? html : null;
      },
    },
    specs: {
      get() {
        const specs = [];
        Object.entries(this.fields).forEach(([groupKey, group]) => {
          const items = [];
          Object.entries(group).forEach(([label, value]) => {
            let val = null;
            switch (label) {
              case 'date':
                val = this.dateHtml;
                break;
              case 'creators':
                val = this.creators;
                break;
              case 'materials':
                val = this.materials;
                break;
              case 'techs':
                val = this.techniques;
                break;
              case 'dimensions':
                val = this.dimensionsHtml;
                break;
              default:
                val = value;
                break;
            }
            if (val) {
              items.push({
                label: this.$t(`specs.${label}`),
                value: val,
              });
            }
          });
          if (items.length) {
            specs.push({
              label: this.$t(`specs.${groupKey}`),
              items,
            });
          }
        });
        return specs;
      },
    },
  },

  methods: {},

  watch: {},
};
